@import '../../../styles/styles.scss';

#services {
    min-height: 400px;
    color: white;
}

#services h3 {
    color: white;
}

#services i {
    vertical-align: middle;
}

#services .fa-circle {
    color: $primary-color;
}

#services .fa-inverse {
    font-size: 22px;
}

.services-text {
    font-size: 14px;
}

.service-title {
    font-weight: 600;
}

.services-link {
    text-decoration: none;
    color: white;

    &:hover {
        text-decoration: none;
        color: white;
    }
}

#services .bg-cover {
    background-image: linear-gradient(rgba(35, 35, 35, 0.8), rgba(35, 35, 35, 0.8)), url('../../../assets/images/services-bg.jpg');
    background-attachment: fixed;
    z-index: -1;
}
