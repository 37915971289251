$primary-color: #00b894;
$secondary-color: #fbc531;

$dark-primary-color: #008561;

$gray: rgb(80, 80, 80);
$gray-light: rgb(120, 120, 120);
$gray-v-light: rgb(170, 170, 170);
$gray-dark: rgb(50, 50, 50);

$font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;

$section-title-dark: rgb(80, 80, 80);
$section-title-light: white;

$nav-primary: rgb(190, 190, 190);
$nav-primary-selected: white;
$nav-primary-dropdown-bg: $gray-light;

$nav-secondary: rgb(90, 90, 90);
$nav-secondary-selected: rgb(20, 20, 20);
$nav-secondary-dropdown-bg: white;
