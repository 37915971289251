.btn {
    padding: 10px 25px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: white !important;
    border-radius: 20px;
    transition: 0.5s;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    text-decoration: none;
    font-weight: 600;
    outline: 0;

    &:hover {
        text-decoration: none;
        background-color: transparent;
        border: 1px solid white;
        color: white;
    }

    &:focus, &:visited {
        outline: 0;
        box-shadow: none;
    }
}

.btn-light {
    color: white;
    border: 1px solid white;
    background-color: transparent;

    &:hover {
        text-decoration: none;
        background-color: $primary-color;
        border: 1px solid $primary-color;
        color: white;
    }
}

.btn-calculator {
    width: 320px;

    &:hover {
        border-color: $primary-color;
        color: $primary-color !important;
    }
}

.image-btn {
    position: relative;
    cursor: pointer;
    background-color: rgba(230, 230, 230, 0.4);
    width: 140px;
    height: 100px;
    border-radius: 4px;

    & .icon {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%);
        font-size: 30px;
        color: rgba(170, 170, 170, 0.8);
    }

    & .text {
        position: absolute;
        color: rgba(170, 170, 170, 0.8);
        top: 55%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 15px;
        font-family: Raleway, Helvetica, Arial, sans-serif;
        font-weight: 600;
    }

    &.active {
        background-color: $primary-color;
        color: white;

        & .text {
            color: white;
        }

        & .icon {
            color: white;
        }
    }
}