@import '../../styles/styles.scss';

$text-color: rgb(113, 117, 121);

#privacy {
    & h3, h5 {
        color: white;
    }

    b {
        color: white;
    }

    a {
        color: $text-color;
    }

    & a:hover, & .pointer:hover {
        text-decoration: none;
        color: rgb(133, 137, 141);
    }

    margin-top: 80px;
    color: $text-color;
}