@import '../../styles/styles.scss';

.navbar-default.scrolled {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
    background-color: white;

    .dropdown-menu {
        background-color: $nav-secondary-dropdown-bg;
    }

    .navbar-nav .nav-item .nav-link, .dropdown-item {
        color: $nav-secondary !important;
        background-color: $nav-secondary-dropdown-bg;

        &:hover, &.active {
            color: $nav-secondary-selected !important;
        }
    }

    .navbar-brand {
        padding-bottom: 5px;
        
        & a, & a:hover {
            color: black;
        }
    }

    &.navbar-dark .navbar-toggler {
        border-color: rgba(90,90,90, 0.5) !important;
    }
    
    &.navbar-dark .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(90,90,90, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
}
