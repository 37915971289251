@import '../../../styles/styles.scss';

#home-about {
    min-height: 400px;
    background-color: rgb(252, 252, 252);

    h3 {
        text-align: center;
    }

    .about-text {
        width: 90%;
        text-align: center;
        margin: auto;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.75;
    }

    i {
        font-size: 45px;
        color: #57606f;
        margin-bottom: 20px;
    }

    .title {
        color: rgb(70, 70, 70);
        font-weight: 600;
        margin-bottom: 5px;
    }

    .desc {
        color: $gray-light;
        font-size: 14px;
    }
}
