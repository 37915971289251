@import '../../../styles/styles.scss';

#conveyancing {
    background-color: rgb(252, 252, 252);

    h3 {
        text-align: center;
    }

    & ul {
        margin: 0;
        padding-top: 10px;

        & li {
            margin-bottom: 6px;
        }
    }
}

#conveyancing-two {
    height: 220px;
    color: white;
    text-align: center;
    font-size: 25px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../../../assets/images/protection-insurance.jpg');
    font-family: 'Raleway', Helvetica, Arial, sans-serif;
    font-weight: 500;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}