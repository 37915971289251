@import '../../../styles/styles.scss';

#contactForm {
    min-height: 300px;
    background-color: rgb(250, 250, 250);

    
    &>div {
        padding: 40px;
    }

    @media(max-width: 767.97px) {
        &>div {
            padding: 20px;
        } 
    }

    h5 {
        color: rgb(30, 30, 30);
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
    }

    @media (max-width: 991.98px) {
        h5, .contact-details-container {
            text-align: center;
        }

        .contact-details-container .d-flex {
            display: block !important;
        }
    }

    button {
        width: 160px;
        height: 45px;
        border: 0;
        outline: 0;
        padding: 10px 25px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        color: white;
        font-weight: 500;
        background-color: $primary-color;
        border-radius: .5em;
        transition: 0.5s;
    }

    input:focus,
    textarea:focus,
    select:focus {
        outline: none;
        box-shadow: none;
    }

    textarea {
        height: 100px !important;
        color: black;
        padding-left: 35px;
    }

    .form-control {
        background-color: rgb(250, 250, 250);
        border: 1px solid $gray-v-light;
        font-size: 14px;
        height: 40px;
        vertical-align: middle;
        -webkit-appearance:none;
    }

    select {
        border-radius: 2px;
        color: $gray-v-light;
        text-indent: 25px;
    }

    input[type] {
        color: black;
        padding-left: 35px;
    }

    ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
        color: $gray-v-light;
    }

    input[type="checkbox"] {
        height: 18px;
        width: 18px;
    }

      .checkbox:checked:before{
        background-color:green;
        }
}

.input-wrapper {
    position: relative;
}

@media (max-width: 991.98px) {
    .contact-details-icon {
        margin-bottom: 5px;
    }
}

#message-container {
    height: 80%;
}

#contact-loader {
    text-align: center;
    color: $gray-v-light;
    align-self: center;
}

.hide {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.loader {
    display: inline-block;
    border: 2px solid transparent;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#contact-success {
    display: none;
    color: $primary-color;
    align-self: center;
    text-align: center;
}

#email-contact-form {
    overflow-wrap: break-word;
    word-wrap: break-word;

    &:hover {
        color: black;
        text-decoration: none;
    }
}

.icon-input {
    position: absolute;
    left: 10px;
    color: $gray-v-light;
}

.top {
    top: 10px;
}

.centered {
    top: 50%;
    transform: translateY(-50%);
}

#map {
    width: 100%;
    max-height: auto;
    object-fit: fill;
    background-position: center center;
}

.contact-text {
    color: $gray;
    font-size: 15px;
}

.contact-text p {
    margin-top: 2px;
    margin-bottom: 2px;
}

#contact-privacy {
    text-decoration: none;
    color: $dark-primary-color;

    &:hover {
        color: #009F7B;
    }
}