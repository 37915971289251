#conveyancing-header {
    min-height: 600px;
    text-align: center;

    h2 {
        font-size: 65px;
        color: white;
        font-family: 'Georgia', sans-serif;
    }
}

#conveyancing-header-img {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../assets/images/conveyancing-header.jpg');
    background-attachment: scroll;
}
