@import '../../styles/styles.scss';

$text-color: rgb(68, 72, 76);
$social-font-size: 15px;

footer {
    background-color: rgb(20, 22, 24);
    font-size: 15px;
    padding: 40px 0 20px 0;
    color: $text-color;

    a {
        color: $text-color;
    }

    & a:hover, & .pointer:hover {
        text-decoration: none;
        color: rgb(133, 137, 141);
    }

    @media (min-width: 768px) {
        & .contact {
            margin-left: 0 !important;
            margin-right: 0 !important;
            display: flex;
        }
    }

    & .contact {
        text-align: center;
    }
}

.social-text {
    font-size: $social-font-size;
}

.social-title {
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .footer-contact-details-icon {
        margin-right: 10px;
    }
}

.footer-contact-details-icon {
    color: $primary-color;
    font-size: 15px;
    margin-bottom: 8px;
}

.contact-text-header {
    color: white;
    font-weight: bold;
    line-height: $social-font-size;
    margin-bottom: 5px;
}

.footer-text {
    color: $text-color;
    font-size: 13px;
}

#social-separator {
    height: 1px;
    width: 100%;
    background-color: $text-color;
    margin: 50px auto 20px auto;
}
