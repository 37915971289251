@import './styles/styles.scss';

.App {
  text-align: center;
}

.section-header-separator {
    height: 3px;
    width: 60px;
    background-color: $primary-color;
    margin: 0 auto 50px auto;
}

.margin-auto {
    margin: 0 auto;
}

.bg-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.section-title-dark {
    color: $section-title-dark;
}

.section-title-light {
    color: $section-title-light;
}

.section-standard {
    position: relative;
    padding: 40px;
}

@media(max-width: 767.98px) {
    .section-standard {
        padding: 20px;
    }
}

.text-gray {
    color: $gray !important;
}

.loud-text {
    color: white;
    font-size: 25px;
}

@media(max-width: 767.98px) {
    .loud-text {
        font-size: 22px;
    }
}

.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.header-text {
    margin-bottom: 25px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;

    h1 {
        font-size: 65px;
        margin-bottom: 15px;
        color: white;
        font-family: 'Georgia', sans-serif;
    }

    div {
        font-size: 20px;
        margin-bottom: 40px;
        color: rgb(210, 210, 210);
    }
}

@media(max-width: 767.98px) {
    .header-text {
        width: 90%;
    }

    .header-text h1 {
        font-size: 32px;
    }

    .header-text div {
        font-size: 14px;
    }
}

@import '../node_modules/bootstrap/scss/bootstrap';

//
// Bootstrap overrides
//
@import 'styles/buttons.scss';

body {
    background-color: rgb(20, 22, 24);
}

.red {
    color: red;
}

@media (max-width: 767px) {
    .navbar {
       padding-left: 0;
       padding-right: 0;
    }

    .navbar-brand {
        padding-left: 16px;
    }

    .navbar-toggler {
        margin-right: 16px;
    }
}

nav {
  font-family: Raleway, Helvetica, Arial, sans-serif;

  .navbar-nav .nav-item .nav-link {
      color: $nav-primary !important;
      cursor: pointer;
      margin: 0 30px;

      &.active, &:hover {
          color: $nav-primary-selected !important;
      }

      &:active {
          color: $nav-primary !important;
      }
  }

  @media (max-width: 767px) {
    .navbar-nav .nav-item .nav-link {
        padding: 5px 16px;
        margin: 0;
    }
  }

  .dropdown-toggle::after {
      margin-left: 5px;
  }

  .nav-link {
      padding: 0;
  }

  .dropdown-menu {
      -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
      width: 200px;
      border: 0;
      border-radius: 0;
      margin-top: 15px;
      padding: 0;
      background-color: $nav-primary-dropdown-bg;
  }

  & .dropdown-item {
      border-bottom: 1px solid #ddd;
      padding: 15px 30px;
      color: $nav-primary;
      background-color: $nav-primary-dropdown-bg;

      &.active, &:hover {
          color: $nav-primary-selected;
          background-color: $nav-primary-dropdown-bg;
      }

      &.no-border {
          border: 0;
      }
  }
}

.navbar-brand {
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;

    & a, & a:hover {
        color: white;
    }
}

.text-small {
    font-size: 14px;
}

.text-15 {
    font-size: 15px;
}

.modal-header, .modal-body, .modal-footer {
    border: 0;
}

.modal-header {
    padding-bottom: 0;
}

.modal-body {
    padding-top: 0;
}

.modal-footer {
    display: block;
}

.modal-dialog {
    max-width: 450px;
}

.form-label {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: rgb(100, 110, 120);
    font-family: Raleway, sans-serif;
}

.form-control, .form-control:focus {
    background-color: rgb(246, 249, 248);
    border: 0;
    height: 40px;
    border-radius: 4px;

    ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
        color: white;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.control-wrapper {
    position: relative;

    &.left-side > div {
        position: absolute;
        margin-right: 10px;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 10px;
        font-size: 14px;
        color: $gray;
    }

    &.right-side > div {
        position: absolute;
        margin-right: 10px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font-size: 14px;
        color: $gray;
    }

    &.left-side > .form-control {
        padding-left: 30px;
    }

    &.right-side > .form-control {
        padding-right: 30px;
    }
}

.pointer {
    cursor: pointer;
}

$card-color: #eb3b5a;

.warning-card {
    background-color: $card-color;
    border-color: $card-color;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: 600;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
}