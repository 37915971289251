@import '../../styles/styles.scss';

#repossessed {
    min-height: 200px;
    position: relative;
    background-color: rgb(245, 242, 238);
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
}

.repossessed {
    font-size: 20px;
    font-weight: 500;
}
