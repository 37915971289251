@import '../../styles/styles.scss';

#contact {
    min-height: 200px;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.88)), url('../../assets/images/contact-bg.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-color: rgb(21, 21, 21);

    button {
        width: 160px;
        height: 45px;
        padding: 10px 25px;
        border-radius: .5em;
    }

}
